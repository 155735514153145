<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div style="display: flex; margin-bottom: 5px">
        <el-form :model="SerachInput" label-width="90px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="充值时间" class="timeRight">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="当前余额">
              <el-input
                v-model="SerachInput.balanceStart"
                placeholder="请输入当前余额"
                style="width: 150px"
                clearable
              ></el-input>
              <span class="zhi">至</span>
              <el-input
                v-model="SerachInput.balanceEnd"
                placeholder="请输入当前余额"
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <el-select
                v-model="SerachInput.type"
                placeholder="请选择"
                clearable
                style="width: 150px"
                @change="selectSys"
              >
                <el-option
                  v-for="item in opStatusla"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="SerachInput.number"
                placeholder="请输入企业编号"
                style="width: 150px"
                clearable
                v-if="isShow"
                @input="$forceUpdate()"
                @change="$forceUpdate()"
              ></el-input>
              <el-select
                v-model="SerachInput.system"
                placeholder="请选择"
                clearable
                style="width: 150px"
                v-else
                @input="$forceUpdate()"
                @change="$forceUpdate()"
              >
                <el-option
                  v-for="item in opStatusSys"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销售分组" class="timeRight">
              <el-select
                v-model="SerachInput.groupId"
                placeholder="请选择"
                clearable
                style="width: 150px"
              >
                <el-option
                  v-for="item in opStatus"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <img
                src="../../assets/images/query.png"
                alt=""
                @click="imaClick"
                style="width: 49px; height: 29px; margin-left: 10px"
              />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="number"
            label="企业编号"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="groupName"
            label="销售分组"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="belongSystemCn"
            label="所属系统"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="lastRechargeTime"
            label="上次充值时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="lastRechargAmount"
            label="上次充值金额"
            width="140px"
          ></el-table-column>
          <el-table-column
            prop="unitPriceShortMsg"
            label="短信费率"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="unitPriceWebCall"
            label="语音费率"
            width="180px"
          ></el-table-column>
          <el-table-column
            prop="creditLimit"
            label="信用额度"
            width="110px"
          ></el-table-column>
          <el-table-column prop="balance" label="当前余额" width="120px">
            <template slot-scope="scope">
              <span><span class="qian">¥</span>{{ scope.row.balance }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="220px">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="handleDelete(scope.$index, scope.row)"
                :disabled="true"
                >充值</el-button
              >
              <el-button
                size="mini"
                @click="configuration(scope.$index, scope.row)"
                >设置</el-button
              >
              <el-button size="mini" @click="mail(scope.$index, scope.row)"
                >站内信</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            查询订单总数: {{ totalCount }}
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
          <div class="buttons">
            <el-button @click="mailList">站内信记录</el-button>
            <el-button @click="exportList">数据导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisibleS"
      width="50%"
      :before-close="handleCloseS"
      :close-on-click-modal="false"
    >
      <el-form ref="formOne" :model="formOne" label-width="auto">
        <el-form-item label="短信通知计费" class="textMessage">
          <el-select
            v-model="formOne.first"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in firstCharging"
              :key="item.value"
              :value="item.value"
              :label="item.label + '元'"
            ></el-option>
          </el-select>
          <el-select
            v-model="formOne.horn"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hornCharging"
              :key="item.value"
              :value="item.value"
              :label="item.label + '角'"
            ></el-option>
          </el-select>
          <el-select
            v-model="formOne.divide"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in divideCharging"
              :key="item.value"
              :value="item.value"
              :label="item.label + '分'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电话通知计费" class="textMessage">
          <el-select
            v-model="formOne.firstOne"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in firstChargingOne"
              :key="item.value"
              :value="item.value"
              :label="item.label + '元'"
            ></el-option>
          </el-select>
          <el-select
            v-model="formOne.hornOne"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in hornChargingOne"
              :key="item.value"
              :value="item.value"
              :label="item.label + '角'"
            ></el-option>
          </el-select>
          <el-select
            v-model="formOne.divideOne"
            @input="$forceUpdate()"
            @change="$forceUpdate()"
            placeholder="请选择"
          >
            <el-option
              v-for="item in divideChargingOne"
              :key="item.value"
              :value="item.value"
              :label="item.label + '分'"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="信用额度" class="creditOne">
          <el-input
            v-model="formOne.creditLimit"
            @input="$forceUpdate()"
            type="number"
            :min="0"
            oninput="value=value.replace(/[^0-99999999999999999999999999]/g,'')"
          ></el-input>
          <span>元</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="unSubmit">保 存</el-button>
      </span>
    </el-dialog>
    <div class="Within">
      <el-dialog
        title="新增站内信"
        :visible.sync="dialogMail"
        width="40%"
        :before-close="handleMail"
        :close-on-click-modal="false"
      >
        <el-form ref="formTwo" :model="formTwo" label-width="auto">
          <el-form-item label="发送系统：">
            <span>{{ formTwo.belongSystemCn }}</span>
          </el-form-item>
          <el-form-item label="选择样式：">
            <el-radio-group
              v-model="formTwo.selectionStyle"
              @change="$forceUpdate()"
              @input="$forceUpdate()"
            >
              <el-radio
                v-for="item in selectStyle"
                :key="item.code"
                :label="item.code"
                >{{ item.name }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发送标题：" class="textMessage">
            <el-input
              v-model="formTwo.title"
              @input="$forceUpdate()"
              @change="$forceUpdate()"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="发送内容：">
            <el-input
              type="textarea"
              :autosize="autosize"
              maxlength="500"
              resize="none"
              show-word-limit
              v-model="formTwo.content"
              @input="$forceUpdate()"
              @change="$forceUpdate()"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="发送时间：">
            <el-date-picker
              v-model="formTwo.timeOne"
              type="date"
              placeholder="选择日期"
              class="sendFirst"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              @input="$forceUpdate()"
              :clearable="false"
              :editable="false"
            >
            </el-date-picker>
            <el-time-picker
              v-model="formTwo.timeTwo"
              class="sendFirstOne"
              placeholder="选择时间"
              format="HH:mm:ss"
              value-format="HH:mm:ss"
              :picker-options="{
                selectableRange: startTimeRange2,
              }"
              @input="formChange2"
              @change="changeTime2"
              :clearable="false"
              :editable="false"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="短信通知管理员：">
            <el-checkbox-group
              v-model="formTwo.administrators"
              @change="$forceUpdate()"
              @input="$forceUpdate()"
            >
              <el-checkbox
                v-for="item in adminTion"
                :label="item.code"
                true-label
                :key="item.code"
                >{{ item.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <div class="wenzi" v-if="isQuetion">
            <span class="wenziOne"
              >站内信一旦发出，无法回撤，请确认站内信内容无误后，输入“确认发送”后点击确认发送按钮</span
            >
            <el-input v-model="formTwo.text" @input="$forceUpdate()"></el-input>
          </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="queSubmit">确认发送</el-button>
        </span>
      </el-dialog>
    </div>
    <div class="WithinOne">
      <el-dialog
        title="站内信管理"
        :visible.sync="dialogStation"
        width="60%"
        :before-close="handleStation"
        :close-on-click-modal="false"
      >
        <el-table :data="tableStation" style="width: 100%" max-height="650">
          <el-table-column
            prop="identifier"
            label="编号"
            width="90px"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="发送标题"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="content"
            label="发送内容"
            width="200px"
            :show-overflow-tooltip="true"
          ></el-table-column>
          <el-table-column prop="belongSystemCn" label="发送系统" width="120px">
            <template slot-scope="scope">
              <span v-if="scope.row.companyNumber != null"
                >{{ scope.row.belongSystemCn }}({{
                  scope.row.companyNumber
                }})</span
              >
              <span v-else>{{ scope.row.belongSystemCn }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sendTime"
            label="发送时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="selectionStyleCn"
            label="样式"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="visitors"
            label="浏览人数"
            width="100px"
          ></el-table-column>
          <el-table-column
            prop="createUserName"
            label="提交人员"
            width="110px"
          ></el-table-column>
        </el-table>
        <!-- <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="queSubmit">确认发送</el-button>
        </span> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      rowMailId: null,
      numOne: 0,
      isQuetion: false,
      tableStation: [],
      dialogStation: false,
      adminTion: [
        {
          code: 0,
          name: "",
        },
      ],
      startTimeRange2: ["00:00:00 - 23:59:59"],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      autosize: { minRows: 8 },
      selectStyle: [],
      formTwo: {
        belongSystemCn: "",
        selectionStyle: "",
        title: "",
        content: "",
        time: "",
        timeTwo: "",
        timeOne: "",
        administrators: [],
        text: "",
      },
      dialogMail: false,
      isShow: false,
      opStatusla: [
        {
          id: "1",
          name: "企业编号",
        },
        {
          id: "2",
          name: "所属系统",
        },
      ],
      opStatusSys: [],
      dialogVisible: false,
      dialogVisibleS: false,
      formOne: {
        creditLimit: "",
        divideOne: "",
        hornOne: "",
        firstOne: "",
        divide: "",
        horn: "",
        first: "",
      },
      firstCharging: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "7",
        },
        {
          value: 8,
          label: "8",
        },
        {
          value: 9,
          label: "9",
        },
      ],
      hornCharging: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "7",
        },
        {
          value: 8,
          label: "8",
        },
        {
          value: 9,
          label: "9",
        },
      ],
      divideCharging: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "7",
        },
        {
          value: 8,
          label: "8",
        },
        {
          value: 9,
          label: "9",
        },
      ],
      firstChargingOne: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "7",
        },
        {
          value: 8,
          label: "8",
        },
        {
          value: 9,
          label: "9",
        },
      ],
      hornChargingOne: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "7",
        },
        {
          value: 8,
          label: "8",
        },
        {
          value: 9,
          label: "9",
        },
      ],
      divideChargingOne: [
        {
          value: 0,
          label: "0",
        },
        {
          value: 1,
          label: "1",
        },
        {
          value: 2,
          label: "2",
        },
        {
          value: 3,
          label: "3",
        },
        {
          value: 4,
          label: "4",
        },
        {
          value: 5,
          label: "5",
        },
        {
          value: 6,
          label: "6",
        },
        {
          value: 7,
          label: "7",
        },
        {
          value: 8,
          label: "8",
        },
        {
          value: 9,
          label: "9",
        },
      ],
      form: {
        pointInformation: "北京市",
        userName: "",
        userPhone: "",
        noticeTypeCn: "",
        noticeStateCn: "",
        deductionAmount: "",
        imei: "",
        productName: "",
        situationCn: "",
        noticeTime: "",
      },
      tableData: [],
      rules: {},
      opSituation: [],
      SerachInput: {
        type: "2",
      },
      overallForm: {
        time: [],
      },
      opProduct: [],
      opStatus: [],
      clientHeight: document.body.clientHeight,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      rowId: 0,
      unitPriceShortMsgOne: "",
      unitPriceWebCallOne: "",
    };
  },
  created() {
    this.getList();
    this.Belonging();
    apiShout.getGroups().then((res) => {
      if (res.data.code === 200) {
        this.opStatus = res.data.result;
      }
    });
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //选择样式字典
    crdMail() {
      api.smallLei({ dictCode: "crdmailstyle" }).then((res) => {
        //console.log('大类下的小类：',res.data);
        if (res.data.code === 200) {
          this.selectStyle = res.data.result;
        }
      });
    },
    mailList() {
      apiShout.getCrdMail({}).then((res) => {
        console.log("列表：", res.data);
        if (res.data.code == 200) {
          this.dialogStation = true;
          this.tableStation = res.data.result;
          this.tableStation.forEach((item, index) => {
            this.$set(this.tableStation[index], "identifier", index + 1);
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    formChange2() {
      this.$forceUpdate();
      var data = new Date();
      var aa = data.getHours();
      var bb = data.getMinutes();
      var cc = data.getSeconds();
      var dd = aa + ":" + bb + ":" + cc;
      // 获取当前时间
      var currentTime = new Date();
      let array = this.formTwo.timeOne.substring(0, 4);
      let array1 = this.formTwo.timeOne.substring(5, 7);
      let array2 = this.formTwo.timeOne.substring(8);
      console.log("shuchushijain:", array, array1, array2);
      // // 设置要比较的年、月、日
      // var yearToCompare = 2021; // 需要比较的年份
      // var monthToCompare = 9; // 需要比较的月份（注意月份从0开始计数）
      // var dayToCompare = 30; // 需要比较的日期

      // 创建一个新的Date对象并传入指定的年、月、日
      var dateToCheck = new Date(array, array1 - 1, array2);

      // 将两个日期进行比较
      if (
        dateToCheck.getFullYear() === currentTime.getFullYear() &&
        dateToCheck.getMonth() === currentTime.getMonth() &&
        dateToCheck.getDate() === currentTime.getDate()
      ) {
        // console.log("该日期是今天");
        this.startTimeRange2 = dd + " - 23:59:59";
      } else {
        this.startTimeRange2 = "00:00:00" + " - 23:59:59";
        // console.log("该日期不是今天");
      }
    },
    changeTime2() {
      console.log("shuchu xianshi d shijain :", this.formTwo.timeTwo);
    },
    queSubmit() {
      console.log("单选框选择 的是：", this.formTwo.select);
      console.log("多选框选择 的是：", this.formTwo.administrators);
      console.log(
        "时间 的是：",
        this.formTwo.timeOne + " " + this.formTwo.timeTwo
      );
      if (this.formTwo.selectionStyle == "") {
        this.$message.error("请选择样式！");
        return false;
      }
      if (this.formTwo.title == "") {
        this.$message.error("请输入发送标题！");
        return false;
      }
      if (this.formTwo.content == "") {
        this.$message.error("请输入发送内容！");
        return false;
      }
      if (this.formTwo.timeOne == "" || this.formTwo.timeTwo == "") {
        this.$message.error("请选择发送时间！");
        return false;
      }
      if (this.numOne == 0) {
        this.isQuetion = true;
        this.numOne = 1;
        return false;
      }
      if (this.formTwo.text !== "确认发送") {
        this.$message.error('请输入"确认发送"!');
        return false;
      }
      if (this.formTwo.administrators.length === 0) {
        //否  0
        this.formTwo.administrators[0] = 0;
      } else if (this.formTwo.administrators[0] === 0) {
        this.formTwo.administrators[0] = 1;
      } else if (this.formTwo.administrators[0] === 1) {
        this.formTwo.administrators[0] = 0;
      }
      // let obj = {
      //   crdCompanyId: this.rowMailId,
      //   content: this.formTwo.content,
      //   selectionStyle: this.formTwo.selectionStyle,
      //   sendTime: this.formTwo.timeOne + " " + this.formTwo.timeTwo,
      //   shortAdmin: this.formTwo.administrators[0],
      //   title: this.formTwo.title,
      // };
      // console.log("输出传过去的值：", obj);
      apiShout
        .addCrdmail({
          crdCompanyId: this.rowMailId,
          content: this.formTwo.content,
          selectionStyle: this.formTwo.selectionStyle,
          sendTime: this.formTwo.timeOne + " " + this.formTwo.timeTwo,
          shortAdmin: this.formTwo.administrators[0],
          title: this.formTwo.title,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.dialogMail = false;
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.formTwo.belongSystemCn = "";
            this.formTwo.selectionStyle = "";
            this.formTwo.title = "";
            this.formTwo.content = "";
            this.formTwo.time = "";
            this.formTwo.timeTwo = "";
            this.formTwo.timeOne = "";
            this.formTwo.administrators = [];
            this.formTwo.text = "";
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    mail(index, row) {
      console.log("输出站内信点击：", index, row);
      this.isQuetion = false;
      this.crdMail();
      this.numOne = 0;
      this.dialogMail = true;
      this.rowMailId = row.id;
      this.formTwo.belongSystemCn = row.belongSystemCn;
    },
    selectSys(aa) {
      this.SerachInput.number = "";
      this.SerachInput.system = "";
      if (aa == 1) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
    configuration(index, row) {
      console.log("输出：", index, row);
      if (row.unitPriceShortMsg == 0) {
        this.formOne.first = this.firstCharging[0].value;
        this.formOne.horn = this.hornCharging[0].value;
        this.formOne.divide = this.divideCharging[0].value;
      } else {
        let str = row.unitPriceShortMsg;
        let strOne = String(str);
        let beforeDot = strOne.substring(0, strOne.indexOf("."));
        // console.log("90909:", beforeDot); // 输出：123
        this.firstCharging.forEach((ele) => {
          if (ele.value == beforeDot) {
            this.formOne.first = ele.value;
          }
        });
        // this.formOne.first = beforeDot + "元";

        // 截取数字在"."之后的字符串
        let afterDot = strOne.substring(strOne.indexOf(".") + 1);
        // console.log("88888:", afterDot[0], afterDot[1]); // 输出
        this.hornCharging.forEach((aa) => {
          if (aa.value == afterDot[0]) {
            this.formOne.horn = aa.value;
          }
        });
        this.divideCharging.forEach((bb) => {
          if (bb.value == afterDot[1]) {
            this.formOne.divide = bb.value;
          }
        });
      }

      // this.formOne.horn = afterDot[0] + "角";
      // this.formOne.divide = afterDot[1] + "分";
      if (row.unitPriceWebCall == 0) {
        this.formOne.firstOne = this.firstChargingOne[0].value;
        this.formOne.hornOne = this.hornChargingOne[0].value;
        this.formOne.divideOne = this.divideChargingOne[0].value;
      } else {
        let strs = row.unitPriceWebCall;
        let strOnes = String(strs);
        let beforeDots = strOnes.substring(0, strOnes.indexOf("."));
        // console.log("90909:", beforeDots); // 输出：123
        this.firstChargingOne.forEach((cc) => {
          if (cc.value == beforeDots) {
            this.formOne.firstOne = cc.value;
          }
        });
        // this.formOne.firstOne = beforeDots + "元";

        // 截取数字在"."之后的字符串
        let afterDots = strOnes.substring(strOnes.indexOf(".") + 1);
        // console.log("88888:", afterDot[0], afterDot[1]); // 输出
        this.hornChargingOne.forEach((dd) => {
          if (dd.value == afterDots[0]) {
            this.formOne.hornOne = dd.value;
          }
        });
        this.divideChargingOne.forEach((ee) => {
          if (ee.value == afterDots[1]) {
            this.formOne.divideOne = ee.value;
          }
        });
      }

      // this.formOne.hornOne = afterDots[0] + "角";
      // this.formOne.divideOne = afterDots[1] + "分";
      this.formOne.creditLimit = row.creditLimit;
      this.dialogVisibleS = true;
      this.rowId = row.id;
    },
    handleCloseS() {
      this.dialogVisibleS = false;
    },
    handleMail() {
      this.dialogMail = false;
      this.formTwo.belongSystemCn = "";
      this.formTwo.selectionStyle = "";
      this.formTwo.title = "";
      this.formTwo.content = "";
      this.formTwo.time = "";
      this.formTwo.timeTwo = "";
      this.formTwo.timeOne = "";
      this.formTwo.administrators = [];
      this.formTwo.text = "";
    },
    handleStation() {
      this.dialogStation = false;
    },
    unSubmit() {
      this.unitPriceShortMsgOne =
        this.formOne.first + "." + this.formOne.horn + this.formOne.divide;
      this.unitPriceWebCallOne =
        this.formOne.firstOne +
        "." +
        this.formOne.hornOne +
        this.formOne.divideOne;
      var obj = {
        id: this.rowId,
        creditLimit: this.formOne.creditLimit,
        unitPriceShortMsg: this.unitPriceShortMsgOne,
        unitPriceWebCall: this.unitPriceWebCallOne,
      };
      console.log("传递过去的：", obj);
      this.$confirm("是否保存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        apiShout
          .upUnit({
            id: this.rowId,
            creditLimit: this.formOne.creditLimit,
            unitPriceShortMsg: this.unitPriceShortMsgOne,
            unitPriceWebCall: this.unitPriceWebCallOne,
          })
          .then((res) => {
            console.log("列表：", res.data);
            if (res.data.code == 200) {
              this.getList();
              this.dialogVisibleS = false;
              this.$message({
                message: "保存成功",
                type: "success",
              });
            } else {
              this.$message.error(res.data.msg);
            }
          });
      });
    },
    imaClick() {
      // this.getList();
      apiShout
        .crdList({
          pageSize: this.pageSize,
          pageCount: 1,
          rechargeStart:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          rechargeEnd:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          balanceStart: this.SerachInput.balanceStart,
          balanceEnd: this.SerachInput.balanceEnd,
          number: this.SerachInput.number,
          groupId: this.SerachInput.groupId,
          belongSystem: this.SerachInput.system,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    getList() {
      apiShout
        .crdList({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          rechargeStart:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          rechargeEnd:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          balanceStart: this.SerachInput.balanceStart,
          balanceEnd: this.SerachInput.balanceEnd,
          number: this.SerachInput.number,
          groupId: this.SerachInput.groupId,
          belongSystem: this.SerachInput.system,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.pageCount = res.data.result.page.pageCount;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/crdcompany/exportList",
          params: {
            rechargeStart:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            rechargeEnd:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            balanceStart: this.SerachInput.balanceStart,
            balanceEnd: this.SerachInput.balanceEnd,
            number: this.SerachInput.number,
            groupId: this.SerachInput.groupId,
            belongSystem: this.SerachInput.system,
          },
          method: "post",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "账户查询" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    Belonging() {
      api.getSelectProduct({ dictCode: "crdbelongsystem" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.opStatusSys = res.data.result;
        }
      });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
  },
};
</script>

<style lang='less' scoped>
.bang {
  font-size: 18px;
  // font-weight: 700;
  margin-bottom: 20px;
}
.inforOne {
  display: flex;
  margin-bottom: 20px;
}
.inforInput {
  width: 582px;
}
.inforDian {
  font-size: 18px;
  // font-weight: 700;
  width: 90px;
  // margin-top: 5px;
}
.police {
  margin-left: 10px;
}
.senOne {
  margin-right: 25px;
}
.xian1 {
  width: 103%;
  height: 2px;
  border-bottom: 1px solid #f3f3f3;
  margin-top: 30px;
  margin-bottom: 20px;
}
.baocun {
  background: hsl(3deg 31% 57%);
  border: none;
  padding: 8px 25px;
  font-size: 21px;
  color: #fff;
  border-radius: 30px;
}
.textMessage {
  /deep/ .el-input {
    width: 85%;
  }
  // /deep/ .el-select {
  //   width: 33%;
  // }
}
.creditOne {
  /deep/ .el-input {
    width: 33%;
    margin-right: 10px;
  }
}
.amount {
  margin-left: 20px;
}
.timeRight {
  margin-right: 15px;
}
.timeRightOne {
  margin-right: 23px;
}
.timeRightOne1 {
  margin-right: 62px;
}
.zhi {
  margin: auto 10px;
}
.qian {
  margin-right: 5px;
}
/deep/ .el-dialog__body {
  padding: 0;
  margin-left: 37px;
  margin-top: 24px;
}
/deep/ .el-input.is-disabled .el-input__inner {
  font-size: 16px;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-input__icon {
  height: auto;
}
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 10px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
.Within {
  /deep/ .el-dialog__body {
    margin: 20px 30px;
  }
  // /deep/ .el-form-item__label-wrap {
  //   margin-left: 0 !important;
  // }
  // /deep/ .el-form-item__content {
  //   margin-left: 0 !important;
  // }
  /deep/ .el-textarea {
    width: 85%;
  }
  .sendFirst {
    width: 41%;
    margin-right: 20px;
  }
  .sendFirstOne {
    width: 41%;
  }
  .wenzi {
    text-align: center;
    /deep/ .el-input__inner {
      width: 16%;
      border: none;
      border-bottom: 1px solid;
      border-radius: 0;
      text-align: center;
    }
  }
  .wenziOne {
    color: #d78673;
  }
  /deep/ .el-dialog__footer {
    text-align: center;
  }
  /deep/ .el-radio__input.is-checked .el-radio__inner {
    border-color: #d78673;
    background: #d78673;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #d78673;
  }
  /deep/ .el-radio__inner:hover {
    border-color: #d78673;
  }
  /deep/ .el-radio__inner {
    border: 1px solid #dcdfe6;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #d78673;
    border-color: #d78673;
  }
  /deep/ .el-checkbox:hover .el-checkbox__inner {
    border-color: #d78673;
  }
  /deep/ .el-checkbox__inner {
    border: 1px solid #dcdfe6;
  }
  /deep/ .el-form-item {
    margin-bottom: 11px;
  }
}
.WithinOne {
  /deep/ .el-dialog__body {
    margin: 20px 30px;
  }
}
</style>
<style>
/* //宽度可以使用max-width显示,占比为屏幕占比，高度用max-height是行不通的,于是就用的超出行省略，当
//在限制的宽度之内多少行限制(不限制当内容几千几万字时就挂了),padding为了调整我这个正好15行省略看
//起来样式不会出问题,有问题的话可以调padding,line-height,font-size保证展示框样式完整  */
.el-tooltip__popper {
  max-width: 30%;
  padding-bottom: 5px !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
}

.el-tooltip__popper,
.el-tooltip__popper is-dark {
  /* background: rgb(48, 65, 86) !important; */
  color: #fff !important;
  line-height: 24px;
}
</style>